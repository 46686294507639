import axios, {
  ResponseData
} from './base.ts'
import {
  AxiosPromise
} from 'axios'
const url = ``

export function getList(data = {}) {
  return axios.request({
    url: `${url}/goods`,
    method: "get",
    params: data,
  })
}

export function getListSelect(data = {}) {
  return axios.request({
    url: `${url}/goods/select`,
    method: "get",
    params: data,
  })
}
export function getDataById(id,params={}){
  return axios.request({
    url: `${url}/good/${id}`,
    method: "get",
    params,
  })
}

export function deleteData(id,data) {
  return axios.request({
    url: `${url}/good/${id}`,
    method: "delete",
    params:data,
  })
}
export function addData(data) {
  return axios.request({
    url: `${url}/good`,
    method: "post",
    data: data,
  })
}
export function editData(id, data) {
  return axios.request({
    url: `${url}/good/${id}`,
    method: "put",
    data: data,
  })
}

export function getTagsList(data = {}) {
  return axios.request({
    url: `${url}/goods/tags`,
    method: "get",
    params: data,
  })
}

export function getTagsListSelect(data = {}) {
  return axios.request({
    url: `${url}/goods/tags/select`,
    method: "get",
    params: data,
  })
}
export function getTagDataById(id,params={}){
  return axios.request({
    url: `${url}/goods/tag/${id}`,
    method: "get",
    params,
  })
}

export function deleteTagData(id,data) {
  return axios.request({
    url: `${url}/goods/tag/${id}`,
    method: "delete",
    params:data,
  })
}
export function addTagData(data) {
  return axios.request({
    url: `${url}/goods/tag`,
    method: "post",
    data: data,
  })
}
export function editTagData(id, data) {
  return axios.request({
    url: `${url}/goods/tag/${id}`,
    method: "put",
    data: data,
  })
}

export function getTypesList(data = {}) {
  return axios.request({
    url: `${url}/goods/types`,
    method: "get",
    params: data,
  })
}

export function getTypesListSelect(data = {}) {
  return axios.request({
    url: `${url}/goods/types/select`,
    method: "get",
    params: data,
  })
}
export function getTypeDataById(id,params={}){
  return axios.request({
    url: `${url}/goods/type/${id}`,
    method: "get",
    params,
  })
}

export function deleteTypeData(id,data) {
  return axios.request({
    url: `${url}/goods/type/${id}`,
    method: "delete",
    params:data,
  })
}
export function addTypeData(data) {
  return axios.request({
    url: `${url}/goods/type`,
    method: "post",
    data: data,
  })
}
export function editTypeData(id, data) {
  return axios.request({
    url: `${url}/goods/type/${id}`,
    method: "put",
    data: data,
  })
}