<template>
  <section>
    <div class="banner">
      <!-- <tools-box v-if="admin_permission.indexOf('look')>-1 || admin_permission.indexOf('add')>-1"
        :config="toolsConfig"
        @openAdd="handleOpenAdd"
        @handleSearch='getTableData'
        @getVersionType="handleGetVersion"
        :showBack="false"
        :showAdd="admin_permission.indexOf('add')>-1"
      >
      </tools-box>
       -->
      <div class="control-box" style="margin-top: 10px;">
        <el-input class="control-box-input" v-model="params.name" placeholder="请输入搜索名字"></el-input>
        <el-button icon="el-icon-search" @click="getTableData" style="margin-left: 10px;">搜索</el-button>
        <el-button icon="el-icon-plus" type="primary" @click="handleOpenAdd">添加商品标签</el-button>
      </div>
    </div>
    <div class="container">
      <!-- 轮播列表 -->
      <el-table :data="tableData" style="width: 100%" class="table" :height="height" v-if="height>0">
        <!-- 数据展示区 -->
        <el-table-column label="名字" prop="name" align="left">
        </el-table-column>

        <el-table-column label="类型" prop="name" align="left">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.type === 0" size="mini">普通</el-tag>
            <el-tag v-if="scope.row.type === 1" size="mini">生肖</el-tag>
          </template>
        </el-table-column>
        
        <el-table-column label="操作" fixed="right" align="center" width="70px">
          <template slot-scope="scope">
            <div
              style="
                  width: 100%;
                  display: inline-flex;
                  justify-content: space-between;
                "
            >
              <el-link style="color: #409eff;" @click="handleEdit(scope.row)">
                <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
                  <i class="el-icon-edit" style="font-size:16px;"></i>
                </el-tooltip>
              </el-link>
              <el-link style="color: #409eff;" @click="handleDelete(scope.row.id)" v-if="scope.row.menu_id !== 'system_management'">
                <el-tooltip class="item" effect="dark" content="刪除" placement="top-start">
                <i class="el-icon-delete" style="font-size:16px;"></i>
                </el-tooltip>
              </el-link>
            
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="params.page"
          :hide-on-single-page="false"
          :small="true"
          :page-size="params.page_count"
          layout="total, prev, pager, next"
          :total="total">
        </el-pagination>
      </div>
      
      <!-- 添加活动信息 -->
      <el-drawer
        title="添加商品标签"
        :visible.sync="addDrawer"
        :direction="direction"
        :before-close="handleClose"
      >
        <add
          @close="handleClose"
          @success="handleDrawerSuccess"
          ref="newForm"
        ></add>
      </el-drawer>

      <!-- 修改活动信息 -->
      <el-drawer
        title="更改商品标签"
        :visible.sync="editDrawer"
        :direction="direction"
        :before-close="handleClose"
      >
        <edit
          ref="newForm"
          v-if="editDrawer"
          @close="handleClose"
          @success="handleDrawerSuccess"
          :id="editParam.id"
        ></edit>
      </el-drawer>
    </div>
  </section>
</template>

<script>
import {getTagsList,deleteTagData} from '@/api/goods'
import ToolsBox from "@/components/tool/Index.vue";
import edit from "./components/edit.vue";
import add from "./components/add.vue";
let page_name = 'app_version'

export default {
  name: "App",
  components: { ToolsBox,edit,add },
  data() {
    return {
      admin_permission:[],
      height:0,
      toolsConfig: ["searchVersion"],

      //搜索条件
      params: {
        page:1,
        page_count: 10
      },
      // 查询数据
      tableData: [
        
      ],
      total: 0,

      // 添加组件传参
      addDrawer: false,
      addParam:{

      },

      // 更新组件传参
      editDrawer: false,
      editParam: {
        id:0
      },

      direction: "rtl",
    };
  },
  mounted() {
    this.admin_permission = this.$store.state.permission.permissionArr[page_name]
    this.getTableData();
    if(document.body.clientHeight&&document.documentElement.clientHeight)
    {
      var clientHeight = (document.body.clientHeight<document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
    }
    else
    {
      var clientHeight = (document.body.clientHeight>document.documentElement.clientHeight)?document.body.clientHeight:document.documentElement.clientHeight;
    }
    console.log(clientHeight)
    this.height = clientHeight-82-60-40-20-20-40
  },
  methods: {
    getTableData(){
      getTagsList(this.params).then(res=>{
        if (res.code === 200){
          console.log(res.data.data)
          this.tableData = res.data.data
          this.params.page = res.data.current_page
          this.params.page_count = res.data.per_page
          this.total = res.data.total
        }
      })
    },
    handleCurrentChange(val){
      console.log(val)
    },
    handleGetVersion(e){
      this.params.type=e
    },
    // 删除
    handleDelete(id) {
      this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
        .then(_ => {
          deleteTagData(id).then(res=>{
              this.getTableData()
              this.$notify({
                title: '成功',
                message: '删除成功',
                type: 'success'
              });
            })
        })
        .catch(_ => {});
    },

    handleOpenAdd() {
      this.addDrawer = true;
    },
    handleEdit(row) {
      this.editParam = {
        id : row.id,
      }
      // console.log(row)
      // console.log(this.editParam)
      this.editDrawer = true
    },
    handleClose() {
      this.$confirm("确认关闭？")
        .then(() => {
          this.addDrawer = false;
          this.editDrawer = false;
          this.$refs.newForm.$refs.form.resetFields(); //清空子组件的内容
        })
        .catch(() => {});
    },
    handleDrawerSuccess(){
      this.addDrawer = false;
      this.editDrawer = false;
      this.getTableData();
      this.$refs.newForm.$refs.form.resetFields();//清空子组件的内容
    },
    // 分页
    prevpage() {
      this.params.page -= 1;
      this.getTableData();
    },
    nextpage() {
      this.params.page += 1;
      this.getTableData();
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.getTableData();
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/static/default";
.banner {
  width: 100%;
  background-color: white;
  padding: 10px 50px;
  // padding-left: 50px;
}
.container {
  background-color: #fff !important;
  padding: 0 32px;
  .table {
    margin-top: 24px !important;
  }
  .toolbar {
    background: #fff;
    width: calc(100% + 64px);
    padding: 16px 32px 26px 0;
    margin-left: -32px;
  }
}

.control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

  .oneLineCls{
	  text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
     text-overflow: ellipsis;
     display: -webkit-box;
     -webkit-line-clamp: 1;
     line-clamp: 1;
     -webkit-box-orient: vertical;
  }
</style>
