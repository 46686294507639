<template>
  <section style="padding:0 5%;">
    <el-form
      ref="form"
      label-position="top"
      status-icon
      :model="form"
      label-width="100px"
      style="width:100%;"
    >
      <el-divider content-position="left">商户基本信息</el-divider>
      <el-form-item label="问题" prop="question">
        <el-input v-model="form.question" placeholder="请输入问题"></el-input>
      </el-form-item>
      <el-form-item label="答案" prop="answer">
        <el-input v-model="form.answer" placeholder="请输入答案"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button @click="close('form')">取消</el-button>
        <el-button type="primary" @click="editData()" :plain="true">立即修改</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>

  let _this;
  import {getDataById,editData} from "@/api/question.js";
  export default {
    props: {
      id: Number
    },
    data() {
      return {
        form: {
          question: "",
          answer: "",
        },
        imageUrl: [],
        show: true,
        placeholder: "",
        innerVisible:false
      };
    },

    computed: {},

    mounted() {
      _this = this;
      _this.init();

    },

    methods: {
      // 初始化数据
      init() {
        console.log(this.id)
        getDataById(this.id).then(res=>{
          if(res.code === 200){
            this.form = res.data
            console.log(this.form)
          }
        })
        // getTypeSelect().then(res=>{
        //   if(res.code === 200){
        //     this.typeOptions = res.data
        //     // console.log(this.typeOptions)
        //   }
        // })
      },
      close() {
        _this.$emit("close");
      },

      editData() {
        editData(_this.id, _this.form).then((res) => {
          if (res.code === 200) {
            _this.$message({
              message: "修改成功",
              type: "success"
            });
            setTimeout(() => {
              _this.$emit("success");
            }, 1000);
          }
        });
      },
    }
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    border: 1px dashed #ccc;
    border-radius: 50%;
    overflow: hidden;
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-radio-group {
    text-align: left !important;
  }
</style>
