<template>
  <section style="height: 100%">
    <div class="container">
      
      <el-main style="width: 100%;box-sizing: border-box;padding-left:20px;">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>商户列表</h1></div>
          <div class="control-box" style="margin-top: 10px;" v-if="admin_permission.indexOf('look')>-1 || admin_permission.indexOf('add')>-1">
            <div>
              <el-input class="control-box-input" style="width:217px;margin-left:10px;margin-top:10px;" v-model="params.name" placeholder="请输入商户名称" clearable></el-input>
              <!-- <el-select v-model="params.store_size" placeholder="请选择商户大小" clearable style="margin-left:10px;width:150px;margin-top:10px;">
                <el-option
                  v-for="item in sizeOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.store_type" placeholder="请选择商户类型" clearable style="margin-left:10px;width:150px;margin-top:10px;">
                <el-option
                  v-for="item in storeTypeOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.lay_type" placeholder="请选择铺设类型" clearable style="margin-left:10px;width:150px;margin-top:10px;">
                <el-option
                  v-for="item in layTypeOptions"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.group_id" placeholder="请选择需搜索企业" filterable clearable style="margin-left:10px;margin-top:10px;" v-if="groupID===1">
                <el-option
                  v-for="item in groupList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select> -->
              <el-select v-model="params.province_id" placeholder="请选择省份" style="margin-left:10px;width:150px;margin-top:10px;" clearable @change="provinceClick()">
                <el-option
                  v-for="item in provinceList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.city_id" placeholder="请选择市" clearable style="margin-left:10px;width:150px;margin-top:10px;" @change="cityClick()">
                <el-option
                  v-for="item in cityList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-select v-model="params.area_id" placeholder="请选择地区" clearable style="margin-left:10px;width:150px;margin-top:10px;" @change="areaClick()">
                <el-option
                  v-for="item in areaList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
              <el-button icon="el-icon-search" @click="getTableData" style="margin-left:10px;margin-top:10px;">搜索</el-button>
              <el-button icon="el-icon-plus" type="primary" @click="handleOpenAdd" style="margin-top:10px;" v-if="admin_permission.indexOf('add')>-1">添加商户</el-button>
            </div>
          </div>
        </div>
        <el-table
          :data="table"
          style="width: 100%;"
          tooltip-effect="dark"
          empty-text="暂无数据"
          class="table"
        >
          <el-table-column label="商户名称" prop="name" align="center" width="120"></el-table-column>
          <el-table-column label="商户省份" prop="province_name" align="center"></el-table-column>
          <el-table-column label="商户城市" prop="city_name" align="center"></el-table-column>
          <el-table-column label="主负责人" prop="realname" align="center"></el-table-column>
          <el-table-column label="联系方式" prop="phone" align="center" width="120"></el-table-column>
          <el-table-column label="地址" prop="address" align="center" width="200"></el-table-column>
          <el-table-column label="分成比例" prop="devided" align="center" width="200">
            <template slot-scope="scope">
              <span>{{parseFloat(scope.row.devided)*100 }}%</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" width="120" align="center" fixed="right">
            <template slot-scope="scope">
              <div
                style="
                  width: 100%;
                  display: inline-flex;
                  justify-content: space-between;
                "
              >
                <el-link style="color: #409eff;" v-if="(scope.row.status !==2 || scope.row.status !==3)" @click="handleDetail(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="查看詳情" placement="top-start">
                    <i class="el-icon-view" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-link style="color: #409eff;" v-if="(scope.row.status !==2 || scope.row.status !==3)" @click="editTable(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
                    <i class="el-icon-edit" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-dropdown @command="command($event,scope.row)">
                  <span class="el-dropdown-link" style="color: #409eff">
                    <el-tooltip class="item" effect="dark" content="更多操作" placement="top-start">
                      <i class="el-icon-arrow-down" style="font-size:16px;"></i>
                    </el-tooltip>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="percent">分成比例</el-dropdown-item>

                    <el-dropdown-item command="block" divided>删除商铺</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :hide-on-single-page="false"
            :small="true"
            :page-size="params.page_count"
            layout="total, prev, pager, next"
            :total="total">
          </el-pagination>
        </div>
      </el-main>
    </div>

    <!-- 查看详情信息 -->
    <el-dialog title="设备详情" v-if="dialogTableVisible" :visible.sync="dialogTableVisible">
      <store-list :id="storeID"></store-list>
    </el-dialog>
    <el-drawer
      title="更改商户信息"
      :visible.sync="editDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <edit-comp v-if="editDrawer" @close="handleClose" @success="handleDrawerSuccess" :id="editID" ref="newForm"></edit-comp>
    </el-drawer>

    <!-- 添加规则 -->
    <el-drawer
      title="添加商户"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <add-comp
        @close="handleClose"
        @success="handleDrawerSuccess"
        @clear="clearContent"
        ref="newForm"
        v-if="addDrawer"
      ></add-comp>
    </el-drawer>
    <el-dialog
      title="设置分成比例"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <div style="margin-top:20px;">分成比例</div>
      <el-input v-model="percent" type="number" placeholder="请输入分成比例" style="width:300px;margin-top:10px;">
        <template slot="append">%</template>
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel()">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
  import { getList, addData,editData,getTypeList,editPercent,deleteData} from "@/api/store.js";
  import { getProvince,getCity,getArea} from "@/api/public.js";
  import { getGroups} from "@/api/system.js";

  import addComp from "./components/add.vue";
  import editComp from "./components/edit.vue";
  import storeList from "@/views/components/storeList.vue";

  let page_name = 'store_list'
  export default {
    name: "App",
    components:{addComp,editComp,storeList},
    data() {
      return {
        percent:'',
        admin_permission:[],
        dialogVisible:false,
        dialogTableVisible:false,
        status: [
          {id:"",label:"全部"},
          {id:0,label:"审核中"},
          {id:1,label:"审核通过"},
          {id:2,label:"审核失败"},
        ],
        treeCurrentNode:"",
        storeTypeOptions:[],
        sizeOptions:[
          {id:0,label:"普通"},
          {id:1,label:"中型"},
          {id:2,label:"大型"},
          {id:3,label:"超大型"}
        ],
        layTypeOptions:[
          {id:0,label:"独家"},
          {id:1,label:"共存"},
        ],
        unitList: [{
          value: 0,
          label: '每半小时'
        }, {
          value: 1,
          label: '每一小时'
        }],
        unit:'',
        unit_price:'',
        percentID:'',
        storeID:'',
        provinceList:[],
        cityList:[],
        areaList:[],
        province_id:"",
        city_id:"",
        params: {
          page: 1,
          page_count: 10,
          username:'',
          gender:'',
          mobile:'',
          status:'',
          nickname:'',
          province_id:'',
          city_id:'',
          area_id:'',
          group_id:'',
          store_type:'',
          lay_type:'',
          store_size:'',
        },
        groupList:[],
        groupID:'',
        table:[],

        service: [],
        editID: '',
        total: 0,
        outerVisible: false,
        innerVisible: false,
        addDrawer: false,
        editDrawer: false,
        direction: "rtl",
        addTradeParams: {
          name: "",
          parent_id: 0
        },
        visible: false,
        scopeForm:{
          id:'',
          sort: ''
        }
      };
    },

    methods: {
      getTableData() {
        getList(this.params).then(res=>{
          if(res.code === 200){
            this.table = res.data.data
            console.log(this.table)
            this.params.page = res.data.current_page
            this.params.page_count = res.data.per_page
            this.total = res.data.total
          }
        })
      },
      searchType(){
        // getTypeList().then(res=>{
        //   if(res.code === 200){
        //     this.storeTypeOptions = res.data
        //     console.log(this.storeTypeOptions)
        //   }
        // })
      },
      provinceClick(){
        let that = this
        this.provinceList.forEach(item => {
          if(item.id == that.params.province_id ){
            that.province_id = item.province_id
            console.log(that.province_id)
          }
        });
        getCity(that.province_id).then(res=>{
          if(res.code === 200){
            console.log(res.data)
            this.cityList = res.data
          }
        })
      },
      cityClick(){
        let that = this
        this.cityList.forEach(item => {
          if(item.id == that.params.city_id ){
            that.city_id = item.city_id
            console.log(that.city_id)
          }
        });
        getArea(that.city_id).then(res=>{
          if(res.code === 200){
            console.log(res.data)
            this.areaList = res.data
          }
        })
      },
      command(e,row){
          console.log(e)
          console.log(row.id)
        if(e === 'ban'){

        }
        if(e === 'percent'){
          this.dialogVisible=true
          this.percentID = row.id
          this.percent = parseFloat(row.devided)*100
        }
        if(e === 'block'){
          this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
        .then(_ => {
          deleteData(row.id).then(res=>{
              this.getTableData()
              this.$notify({
                title: '成功',
                message: '删除成功',
                type: 'success'
              });
            })
        })
        .catch(_ => {});
        }
      },
      cancel(){
        this.dialogVisible=false
      },
      confirm(){
        let _this = this
        if(this.percent>100 ){
          return _this.$confirm('分成比例不能超过100，请重新输入！')
        }
        if(this.percent<0 ){
          return _this.$confirm('分成比例不能低于0，请重新输入！')
        }
        this.dialogVisible=false
        let unitParams ={}
        unitParams.devided=this.percent/100
        
        
        editPercent(this.percentID,unitParams).then((res) => {
          if (res.code === 200) {
            _this.$message({
              message: "修改成功",
              type: "success"
            });
            _this.getTableData()
          }
        });
      },
      writeScope(id){
        console.log(id)
        this.scopeForm.id = id
        let tmp = [];
        this.service.forEach((value,key)=>{
          tmp[key] = value
          if (id === value.id){
            this.scopeForm.sort = parseInt(value.sort)
            tmp[key].write_scope = true
          }
        })
        this.service = tmp;
      },

      scopeChange(){
        editData(this.scopeForm.id,{sort:this.scopeForm.sort}).then(res=>{
          if (res.code === 200){
            this.searchSet()
          }
        })
      },

      //选择TREE
      showTreeWorker(id, pid) {
          this.params.status = id;
          this.getTableData();
      },

      handleDetail(e){
        console.log(e)
        this.storeID=e
        this.dialogTableVisible = true
      },
      // 打开添加
      handleOpenAdd() {
        this.addDrawer = true;
      },
      // 关闭添加/修改
      handleClose() {
        this.$confirm("确认关闭？")
          .then((_) => {
            this.addDrawer = false;
            this.editDrawer = false;
            this.dialogVisible=false
          })
          .catch((_) => {});
      },

      editTable(id){
        this.editID = id
        this.editDrawer = true
      },
      // 添加/修改成功
      handleDrawerSuccess() {
        this.getTableData()
        this.addDrawer = false;
        this.editDrawer = false;
        // this.searchSet()
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 清空子组件
      clearContent() {
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 分页
      prevpage() {
        this.params.page -= 1;
        this.getTableData();
      },
      nextpage() {
        this.params.page += 1;
        this.getTableData();
      },
      handleCurrentChange(val) {
        this.params.page = val;
        this.getTableData();
      },
    },
    mounted() {
      this.admin_permission = this.$store.state.permission.permissionArr[page_name]
      this.getTableData();
      this.searchType();
      
      this.groupID = this.$store.state.user.userData.group_id
      getGroups().then(res=>{
        if(res.code === 200){
          this.groupList = res.data
        }
      })
      getProvince().then(res=>{
        if(res.code === 200){
          this.provinceList = res.data
        }
      })
    }
  };
</script>

<style scoped lang="scss">
  @import "@/static/default";

  .container {
    width: 100%;
    min-height: 100%;
    background-color: #fff !important;
    padding: 20px 32px;
    padding-bottom:0;
    display: inline-flex;

    .table {
      margin-top: 24px !important;
    }

    .toolbar {
      background: #fff;
      width: 100%;
      padding: 16px 32px 26px 0;
    }
  }

  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
  .el-main {
    padding: 0;
  }

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>
<style>
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 0;
    padding-right: 8px;
  }
  .el-tree-node__expand-icon.is-leaf{
    color: #c0c4cc;
  }
</style>
