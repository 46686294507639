<template>
  <section style="height: 100%">
    <div class="container">
      <!-- <el-aside style="padding-right: 20px;border-right:1px solid #e9eaf2;width: 150px;">
        <h1>账号状态</h1>
        <el-tree
          style="margin-top: 10px;margin-bottom:10px;"
          :data="status"
          node-key="id"
          icon-class="el-icon-s-flag"
          default-expand-all
          :expand-on-click-node="false"
          :highlight-current="true"
          :current-node-key="treeCurrentNode"
        >
          <span
            class="custom-tree-node"
            slot-scope="{ node, data }"
            @click="showTreeWorker(data.id, data.pid)"
          >
            <span>
              {{ data.label }}
            </span>
          </span>
        </el-tree>
      </el-aside> -->
      <el-main style="width: 100%;box-sizing: border-box;padding-left:20px;">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>管理员账号</h1></div>
          <div class="control-box" style="margin-top: 10px;" v-if="admin_permission.indexOf('look')>-1 || admin_permission.indexOf('add')>-1">
            <el-input class="control-box-input" v-model="params.username" placeholder="请输入搜索账号" clearable></el-input>
            <el-select v-model="params.group_id" placeholder="请选择需搜索企业" filterable clearable style="margin-left:10px;" v-if="groupID===1">
              <el-option
                v-for="item in groupList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="params.tag_id" placeholder="请选择需搜索标签" clearable style="margin-left:10px;">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.tag_id">
              </el-option>
            </el-select>
            <el-button v-if="admin_permission.indexOf('look')>-1" icon="el-icon-search" @click="getTableData" style="margin-left:10px;">搜索</el-button>
            <el-button v-if="admin_permission.indexOf('add')>-1" icon="el-icon-plus" type="primary" @click="handleOpenAdd">添加账号</el-button>
          </div>
        </div>
        <el-tabs v-model="activeName" @tab-click="handleClick" style="margin-top:10px;">
          <el-tab-pane label="全部用户" name="all"></el-tab-pane>
          <el-tab-pane label="正常用户" name="default"></el-tab-pane>
          <el-tab-pane label="已禁用用户" name="delete"></el-tab-pane>
        </el-tabs>
        <el-table
          :data="table"
          style="width: 100%;"
          tooltip-effect="dark"
          empty-text="暂无数据"
          class="table"
        >
          <el-table-column label="ID" prop="id" align="left" width="80"></el-table-column>
          <el-table-column label="账号" prop="username" align="left" width="140px"></el-table-column>
          <el-table-column label="昵称" prop="nickname" align="left" width="150px"></el-table-column>
          <el-table-column label="实名认证" prop="realname_auth" align="left" width="100px">
            <template slot-scope="scope">
              <span v-if="(scope.row.realname_auth===0)">未认证</span>
              <el-tag v-if="(scope.row.realname_auth===1)" effect="dark" type="warning" size="mini">认证</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="公司" prop="group" align="left" width="240px">
            <template slot-scope="scope">
              {{scope.row.group.name}}
            </template>
          </el-table-column>
          <el-table-column label="角色" prop="role" align="left" width="160px">
            <template slot-scope="scope">
              <el-tag v-for="(v,k) in scope.row.role" effect="light" size="mini" :hit="false">{{v.role_name}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="标签" prop="tags" align="left" width="160px">
            <template slot-scope="scope">
              <el-tag v-for="(v,k) in scope.row.tags" effect="dark" size="mini" :color="v.color" :hit="false">{{v.name}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="状态" prop="status" align="left">
            <template slot-scope="scope">
              <span v-if="(scope.row.status===0)">正常</span>
              <el-tag v-if="(scope.row.status===1)" effect="dark" type="info" size="small">禁用</el-tag>
              <!-- <el-tag v-if="(scope.row.status===2)" effect="dark" type="" size="small">审核中</el-tag>
              <el-tag v-if="(scope.row.status===3)" effect="dark" type="danger" size="small">审核拒绝</el-tag> -->
            </template>
          </el-table-column>

          <el-table-column label="操作" width="120" align="center" fixed="right"  v-if="admin_permission.indexOf('update')>-1 || admin_permission.indexOf('deleted')>-1 || admin_permission.indexOf('examine')>-1">
            <template slot-scope="scope">
              <div
                style="
                  width: 100%;
                  display: inline-flex;
                  justify-content: flex-end;
                "
              >
                <!-- <el-link style="color: #409eff;" @click="editTable(scope.row.id)" v-if="admin_permission.indexOf('look')>-1">
                  <el-tooltip class="item" effect="dark" content="查看詳情" placement="top-start">
                    <i class="el-icon-view" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link> -->
                <el-link style="color: #409eff;" v-if="(scope.row.status !==2 || scope.row.status !==3) && admin_permission.indexOf('update')>-1" @click="editTable(scope.row.id)">
                  <el-tooltip class="item" effect="dark" content="修改" placement="top-start">
                    <i class="el-icon-edit" style="font-size:16px;"></i>
                  </el-tooltip>
                </el-link>
                <el-dropdown @command="command($event,scope.row.id)" style="margin-left:10px;">
                  <span class="el-dropdown-link" style="color: #409eff">
                    <el-tooltip class="item" effect="dark" content="更多操作" placement="top-start">
                      <i class="el-icon-arrow-down" style="font-size:16px;"></i>
                    </el-tooltip>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="resetPass" v-if="admin_permission.indexOf('examine')>-1">重置密码</el-dropdown-item>
                    <el-dropdown-item command="ban" v-if="(scope.row.status === 0) && admin_permission.indexOf('examine')>-1">禁用账号</el-dropdown-item>
                    <el-dropdown-item command="unban" v-if="(scope.row.status === 1) && admin_permission.indexOf('examine')>-1">解禁账号</el-dropdown-item>
                    <!-- <el-dropdown-item command="block" v-if="admin_permission.indexOf('deleted')>-1">拉黑账号</el-dropdown-item> -->
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="width:100%;display:flex;justify-content: flex-end;box-sizing: border-box;padding: 10px;">
          <el-pagination
            @current-change="handleCurrentChange"
            :current-page.sync="params.page"
            :hide-on-single-page="false"
            :small="true"
            :page-size="params.page_count"
            layout="total, prev, pager, next"
            :total="total">
          </el-pagination>
        </div>
      </el-main>
    </div>

    <el-drawer
      title="更改账号"
      :visible.sync="editDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <edit-comp v-if="editDrawer" @close="handleClose" @success="handleDrawerSuccess" :id="editID" ref="newForm"></edit-comp>
    </el-drawer>

    <!-- 添加规则 -->
    <el-drawer
      title="添加账号"
      :visible.sync="addDrawer"
      :direction="direction"
      :before-close="handleClose"
    >
      <add-comp
        @close="handleClose"
        @success="handleDrawerSuccess"
        @clear="clearContent"
        ref="newForm"
        v-if="addDrawer"
      ></add-comp>
    </el-drawer>
  </section>
</template>

<script>
  import { searchAdmins, addAdmin,updateAdmin,blockAdmin,unblockAdmin,searchTags,searchGroups} from "@/api/system.js";

  import addComp from "./components/add.vue";
  import editComp from "./components/edit.vue";
  let page_name = 'system_admin'
  export default {
    name: "App",
    components:{addComp,editComp},
    data() {
      return {
        activeName: 'all',
        admin_permission:[],
        groupID:'',
        status: [
          {id:"",label:"全部"},
          {id:0,label:"正常"},
          {id:1,label:"禁用"},
          {id:2,label:"审核中"},
          {id:3,label:"审核拒绝"},
        ],
        options:[],
        groupList:[],
        treeCurrentNode:"",

        params: {
          page: 1,
          page_count: 10,
          username:'',
          gender:'',
          mobile:'',
          status:'',
          nickname:'',
          group_id:'',
          tag_id:''
        },

        table:[],

        service: [],
        editID: '',
        total: 0,
        outerVisible: false,
        innerVisible: false,
        addDrawer: false,
        editDrawer: false,
        direction: "rtl",
        addTradeParams: {
          name: "",
          parent_id: 0
        },
        visible: false,
        scopeForm:{
          id:'',
          sort: ''
        }
      };
    },

    methods: {
      handleClick(tab, event) {
        if(this.activeName === 'all'){
          this.params.status = ''
        }
        if(this.activeName === 'default') this.params.status = 0
        if(this.activeName === 'delete') this.params.status = 1
        this.getTableData()
      },
      getTableData() {
        searchAdmins(this.params).then(res=>{
          if(res.code === 200){
            this.table = res.data.data
            this.params.page = res.data.current_page
            this.params.page_count = res.data.per_page
            this.total = res.data.total
          }
        })
      },

      command(e,id){
        if(e === 'ban'){
          this.$confirm('此操作将禁用此账号, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            blockAdmin(id).then(res => {
              if (res.code === 200) {
                this.$message({
                  message: "已禁用",
                  type: "success"
                });
              }
              this.getTableData()
            })
          }).catch(() => {
          });
        }
        if(e === 'unban'){
          this.$confirm('确定解禁该账号吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            unblockAdmin(id).then(res => {
              if (res.code === 200) {
                this.$message({
                  message: "已解禁",
                  type: "success"
                });
                this.getTableData()
              }
            })
          }).catch(() => {
          });
        }
        if(e === 'block'){
        }
      },

      writeScope(id){
        console.log(id)
        this.scopeForm.id = id
        let tmp = [];
        this.service.forEach((value,key)=>{
          tmp[key] = value
          if (id === value.id){
            this.scopeForm.sort = parseInt(value.sort)
            tmp[key].write_scope = true
          }
        })
        this.service = tmp;
      },

      scopeChange(){
        editData(this.scopeForm.id,{sort:this.scopeForm.sort}).then(res=>{
          if (res.code === 200){
            this.searchSet()
          }
        })
      },

      //选择TREE
      showTreeWorker(id, pid) {
          this.params.status = id;
          this.getTableData();
      },

      // 打开添加
      handleOpenAdd() {
        this.addDrawer = true;
      },
      // 关闭添加/修改
      handleClose() {
        this.$confirm("确认关闭？")
          .then((_) => {
            this.addDrawer = false;
            this.editDrawer = false;
          })
          .catch((_) => {});
      },

      editTable(id){
        this.editID = id
        this.editDrawer = true
      },
      // 添加/修改成功
      handleDrawerSuccess() {
        this.addDrawer = false;
        this.editDrawer = false;
        this.getTableData();
        this.searchSet()
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 清空子组件
      clearContent() {
        this.$refs.newForm.$refs.form.resetFields();
      },
      // 分页
      prevpage() {
        this.params.page -= 1;
        this.getTableData();
      },
      nextpage() {
        this.params.page += 1;
        this.getTableData();
      },
      handleCurrentChange(val) {
        this.params.page = val;
        this.getTableData();
      },
    },
    mounted() {
      this.getTableData();
      this.admin_permission = this.$store.state.permission.permissionArr[page_name]
      this.groupID = this.$store.state.user.userData.group_id
      searchTags().then(res=>{
        if(res.code === 200){
          this.options = res.data.data
        }
      })
      searchGroups().then(res=>{
        if(res.code === 200){
          console.log(res.data.data)
          this.groupList = res.data.data
        }
      })
    }
  };
</script>

<style scoped lang="scss">
  @import "@/static/default";

  .container {
    width: 100%;
    min-height: 100%;
    background-color: #fff !important;
    padding: 20px 32px;
    padding-bottom:0;
    display: inline-flex;

    .table {
      margin-top: 24px !important;
    }

    .toolbar {
      background: #fff;
      width: 100%;
      padding: 16px 32px 26px 0;
    }
  }

  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    &-input{
      width: 150px;
    }
  }
  .el-main {
    padding: 0;
  }

  .demo-table-expand {
    font-size: 0;
  }

  .demo-table-expand label {
    width: 90px;
    color: #99a9bf;
  }

  .demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
  }
</style>
<style>
  .custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 10px 0;
    padding-right: 8px;
  }
  .el-tree-node__expand-icon.is-leaf{
    color: #c0c4cc;
  }
</style>
