import axios, {
  ResponseData
} from './base.ts'
import {
  AxiosPromise
} from 'axios'

const url = ''
export function getTable(data = {}) {
  return axios.request({
    url: `${url}/orders`,
    method: "get",
    params: data,
  })
}

export function getByID(id, data = {}) {
  return axios.request({
    url: `${url}/order/${id}`,
    method: "get",
    params: data,
  })
}

export function updateExpress(id, data) {
  return axios.request({
    url: `${url}/order/express/${id}`,
    method: "put",
    data: data,
  })
}

export function updateStatus(id, data) {
  return axios.request({
    url: `${url}/order/status/${id}`,
    method: "put",
    data: data,
  })
}
